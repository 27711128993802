.mainView {
  background-color: #ffffff;
  min-height: calc(100vh - 180px);
  border-radius: 8px;
}

.listBtnMainDiv {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.listBtnDiv {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #000;
  border-radius: 8px;
  cursor: pointer;
  height: 100px;
}

.listBtnDivText {
  color: #fff;
  font-size: 12.5px !important;
  font-family: GilroySemiBold !important;
  text-align: center;
}
