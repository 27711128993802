.mainView {
  background-color: #ffffff;
  height: calc(100vh - 180px);
  border-radius: 8px;
}

.fileListView {
  display: flex;
  flex-direction: row !important;
  align-items: center;
  border: 1px solid #aaaaaa;
  border-radius: 8px;
  padding: 10px;
}

.fileNameText {
  flex: 1;
  color: #000000;
  font-size: 14px !important;
  font-family: GilroySemiBold !important;
}

.noteText {
  color: #AAA;
  font-size: 12px !important;
  font-family: GilroyMedium !important;
}

.addBtnView {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #000000;
  width: 100%;
  height: 50px;
  border-radius: 8px;
  padding: 0 18px;
  cursor: pointer;
}

.addBtnText {
  color: #ffffff;
  font-size: 14px !important;
  font-family: GilroySemiBold !important;
}
