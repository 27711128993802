.mainView {
  background-color: #ffffff;
  min-height: calc(100vh - 180px);
  border-radius: 8px;
}

.textInputLabel {
  color: #000000 !important;
  font-size: 14.5px !important;
  font-family: GilroySemiBold !important;
}

.toggle-switch-label {
  display: flex;
  align-items: center;
  color: #000000;
  font-size: 14.5px !important;
  font-family: GilroySemiBold !important;
}

.saveBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #000000 !important;
  width: 20%;
  height: 48px;
  border-radius: 8px !important;
  padding: 0 18px;
  color: #ffffff !important;
  font-size: 14px !important;
  font-family: GilroySemiBold !important;
  cursor: pointer;
}

.addEntry {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid !important;
  width: 120px;
  height: 42px;
  border-radius: 2px !important;
  padding: 0 18px;
  color: #000 !important;
  font-size: 14px !important;
  font-family: GilroySemiBold !important;
  cursor: pointer;
}
