.mainView {
  background-color: #ffffff;
  min-height: calc(100vh - 180px);
  border-radius: 8px;
}

.subscriptionView {
  border: 1px solid #e7e7e7;
  border-radius: 20px;
  min-width: 285px;
}

.activePlanView {
  min-height: 30px;
}

.activePlan {
  background-color: #fe724c;
  cursor: pointer;
  text-align: center;
  font-family: GilroySemiBold !important;
  color: #fff;
  font-size: 10px !important;
  border-radius: 30px;
  width: 100px;
}

.planTitle {
  color: #272d2f;
  font-size: 30px !important;
  font-family: GilroyBold !important;
}

.planPriceView {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.planPrice {
  color: #4b4b4b;
  font-size: 15px !important;
  font-family: GilroySemiBold !important;
}

.planDuration {
  color: #4b4b4b;
  font-size: 10px !important;
  font-family: GilroyMedium !important;
}

.featureTitleText {
  color: #263238;
  font-size: 17px !important;
  font-family: GilroyBold !important;
}

.featureView {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid #f5f4f4;
}
.featureText {
  flex: 1;
  color: #263238;
  font-size: 14px !important;
  font-family: GilroyMedium !important;
}

.menuDisplayImg {
  height: 70px;
}
