.main-view {
}

.main-view .open-hrs-view {
  align-items: center;
  min-height: 60px;
  padding-left: 20px;
}

.main-view .all-day-view {
  border: 2px solid #ffe342;
  min-height: 80px;
}

.main-view .apply-btn-view {
  display: flex;
  align-items: center;
}

.main-view .apply-btn {
  background-color: #ffe342;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  text-transform: capitalize;
  color: #000;
  font-family: GilroyBold;
  font-size: 13px;
  border-radius: 1px;
  width: 100px;
  height: 35px;
  margin: 10px 0;
}

.main-view .apply-btn:hover {
  background-color: #ffe342;
}
