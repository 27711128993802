.headerView {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.addBtnView {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #000000;
  width: fit-content;
  height: 40px;
  border-radius: 8px;
  padding: 0 18px;
  cursor: pointer;
}

.addBtnText {
  color: #ffffff;
  font-size: 14px !important;
  font-family: GilroySemiBold !important;
}

.arModelListMainView {
  background-color: #ffffff;
  border-radius: 8px;
}

.arModelListView {
  height: calc(100vh - 200px);
  overflow: auto;
}

.arModelMainView {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #ffffff;
  border: 1px solid #d6d6d6;
  border-radius: 8px;
}

.arModelView {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.slNoMainView {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 70px;
}

.slNoView {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #000000;
  width: 30px;
  height: 30px;
  border-radius: 15px;
}

.slNoText {
  color: #ffffff;
  font-size: 15px !important;
  font-family: GilroySemiBold !important;
}

.menuImgView {
  width: 65px;
  height: 65px;
  border-radius: 11px;
  overflow: hidden;
}

.menuImg {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #eeeeee;
  width: 100%;
  height: 100%;
}

.imageStyle {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.arModelDataView {
}

.menuName {
  color: #000;
  font-size: 15px !important;
  font-family: GilroyBold !important;
}

.menuPrice {
  color: #ff0000;
  font-size: 15px !important;
  font-family: GilroySemiBold !important;
}

.menuDesc {
  color: #9f9f9f;
  font-size: 12px !important;
  font-family: GilroySemiBold !important;
}

.viewModelBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #000000;
  width: fit-content;
  height: 35px;
  border-radius: 8px;
  padding: 0 16px;
  cursor: pointer;
}

.viewModelBtnText {
  color: #ffffff;
  font-size: 13px !important;
  font-family: GilroySemiBold !important;
  text-align: center;
}

.noModelTextView {
  height: calc(100vh - 230px);
  display: flex;
  align-items: center;
  justify-content: center;
}

.noModelText {
  color: #000000;
  font-size: 16px !important;
  font-family: GilroySemiBold !important;
  text-align: center;
}
