.hide-img-thumbnail {
  display: flex;
  align-items: center;
}

.take-away-price.currency {
  font-size: 13px;
  font-family: GilroyBold;
  padding: 4px 0 5px;
  height: 30px;
}

.take-away-price.price {
  font-size: 13px;
  font-family: GilroyBold;
  height: 30px;
}
