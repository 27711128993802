.tabBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  text-transform: capitalize !important;
  font-family: GilroySemiBold !important;
  font-size: 15px;
  color: #000;
  border-radius: 5px 5px 0px 0px !important;
  width: 150px;
  height: 42px;
}

.mainView {
  background-color: #ffffff;
  min-height: calc(100vh - 180px);
  border-radius: 8px;
}
