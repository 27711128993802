.modelView {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
}

.dataView {
  display: flex;
  position: relative;
}

.orderIdText {
  font-family: GilroySemiBold !important;
  font-size: 15px !important;
}

.orderDateText {
  font-family: GilroyRegular !important;
  font-size: 10px !important;
  line-height: 15px !important;
}

.nameText {
  font-family: GilroyMedium !important;
  font-size: 13px !important;
  line-height: 14px !important;
}

.headerRightView {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
}

.orderTypeView {
  width: 100px;
  border-radius: 4px;
}

.orderTypeText {
  text-transform: capitalize;
  text-align: center;
  font-family: GilroySemiBold !important;
  font-size: 13px !important;
}

.priceText {
  font-family: GilroyBold !important;
  font-size: 20px !important;
}

.menuListView {
  overflow: auto;
}

.orderItemText {
  font-family: GilroyBold !important;
  font-size: 18px !important;
}

.btnMainView {
  display: flex;
  justify-content: center;
  align-items: center;
}

.btnView {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 170px;
  height: 40px;
  border-radius: 10px;
  cursor: pointer;
}

.btnText {
  font-family: GilroySemiBold !important;
  font-size: 13px !important;
}

.order-type-tbl-view {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.dataView .tbl-num {
  color: #ffffff;
  font-family: GilroyMedium;
  font-size: 15px;
  margin-bottom: 5px;
}

.bill-view {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.bill-view .bill-label {
  display: flex;
  flex: 1;
  color: #000000;
  font-family: GilroySemiBold;
  font-size: 14px;
}

.bill-view .bill-val {
  color: #000000;
  font-family: GilroySemiBold;
  font-size: 14px;
}

.dataView .transaction-id {
  color: #ffffff;
  font-family: GilroyRegular;
  font-size: 10px;
}

.dataView .transaction-status {
  color: #ffffff;
  font-family: GilroyRegular;
  font-size: 13px;
}

.close-icon {
  cursor: pointer;
}
