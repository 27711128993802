.mainView {
  background-color: #ffffff;
  min-height: calc(100vh - 180px);
  border-radius: 8px;
}

.textInputLabelView {
  display: flex;
  align-items: center;
}

.textInputLabel {
  color: #000000;
  font-size: 14.5px !important;
  font-family: GilroySemiBold !important;
}

.btnMainView {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.editBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff !important;
  border: 2px solid #000000 !important;
  width: 100%;
  height: 48px;
  border-radius: 8px !important;
  padding: 0 18px;
  color: #000000 !important;
  font-size: 14px !important;
  font-family: GilroySemiBold !important;
  cursor: pointer;
}

.saveBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #000000 !important;
  width: 100%;
  height: 48px;
  border-radius: 8px !important;
  padding: 0 18px;
  color: #ffffff !important;
  font-size: 14px !important;
  font-family: GilroySemiBold !important;
  cursor: pointer;
  margin-left: 10px !important;
}
