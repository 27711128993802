@font-face {
    font-family: "GilroyBlack";
    src: url("../fonts/gilroy/Gilroy-Black.ttf");
}

@font-face {
    font-family: "GilroyBold";
    src: url("../fonts/gilroy/Gilroy-Bold.ttf");
}

@font-face {
    font-family: "GilroySemiBold";
    src: url("../fonts/gilroy/Gilroy-SemiBold.ttf");
}

@font-face {
    font-family: "GilroyLight";
    src: url("../fonts/gilroy/Gilroy-Light.ttf");
}

@font-face {
    font-family: "GilroyRegular";
    src: url("../fonts/gilroy/Gilroy-Regular.ttf");
}

@font-face {
    font-family: "GilroyMedium";
    src: url("../fonts/gilroy/Gilroy-Medium.ttf");
}

html, body, #root {
    height: 100%
}

body {
    margin: 0 !important;
    overflow: visible !important;
    padding-right: 0 !important;
    background-color: #EEEEEE !important;
}

.MuiAppBar-root {
    padding-right: 0 !important;
}

.login-main-grid {
    height: 100%;
}

.login-logo-section {
    display: flex;
    justify-content: center;
    border-top-left-radius: 13px;
    border-top-right-radius: 13px;
    height: 100px;
}

.login-grid-height {
    height: 100%;
}

.login-form-main-section {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 95%;
}

.login-logo {
    align-self: center;
}

.login-form-section {
    background-color: #2f4353;
    background-image: linear-gradient(315deg, #2f4353 0%, #d2ccc4 74%);
    border-bottom-left-radius: 13px;
    border-bottom-right-radius: 13px;
    padding: 50px;
}

.margin-bottom-20px {
    margin-bottom: 20px
}

.menu-grid-list-view-grid {
    display: flex;
    justify-content: center;
    float: right;
    background-color: #fff;
    border: 1px solid #fff;
    border-radius: 7px;
    margin-bottom: 20px;
    padding: 5px;
}

.menu-grid-view-icon {
    margin-right: 12px;
    cursor: pointer;
}

.cursor-pointer {
    cursor: pointer;
}

.menu-arrow-icon-grid {
    display: flex;
    justify-content: center;
    background-color: #fff;
    border: 1px solid #fff;
    border-radius: 10px;
    padding: 12px;
    margin-top: 10px;
}

.pagination-main-grid {
    display: flex;
    justify-content: center;
    margin-top: 5px;
}

.pagination-grid {
    background-color: #fff;
    border: 1px solid #fff;
    border-radius: 20px;
    padding: 2px;
    margin-bottom: 10px;
}

/*add restaurant info*/
.restaurant-first-data-grid {
    margin-top: 15px !important;
}

.restaurant-second-data-grid {
    margin-top: 6px !important;
}

.restaurant-data-grid {
    margin-top: 30px !important;
}

.restaurant-main-option {
    flex-direction: row !important;
}

.restaurant-option {
    margin-right: 40px !important;
}

.restaurant-label {
    font-size: 12px;
}

/*restaurant images upload*/

.image-upload-grid {
    display: flex;
    flex-direction: column !important;
    text-align: center;
    align-items: center;
    height: fit-content;
    border-style: dashed !important;
    border-radius: 10px;
    padding-top: 50px;
    padding-bottom: 50px;
}

.image-drag-text {
    font-size: 12px !important;
    margin-bottom: 10px !important;
}

.image-upload-text {
    font-size: 12px !important;
    margin-bottom: 20px !important;
}

.image-browse-grid {
    cursor: pointer;
    border-radius: 7px;
    margin-bottom: 12px;
    padding: 3px 20px 3px 20px;
}

.image-browse-text {
    font-size: 12px !important;
    /*font-weight: bold !important;*/
}

.image-list-grid {
    align-items: center;
}

/*restaurant-opening-hours*/

.day-label {
    width: 120px;
    font-size: 13px;
    text-transform: capitalize;
}

/*restaurant-location*/

.location-pin-main-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
    margin: 0;
}

.location-pin-box {
    display: flex;
    background-color: rgba(222, 79, 84, 0.3);
    justify-content: center;
    align-items: center;
    border: 1px solid #ffdad9;
    border-radius: 35px;
    width: 70px;
    height: 70px;
}

.gm-ui-hover-effect {
    display: none !important;
}

.hover-top-cut {
    width: 48px;
    height: 48px;
    background-color: #ffe342;
    border-radius: 0 0 30px;
    position: absolute;
    top: -48px;
    right: 0;
    z-index: 2;
}

.hover-top-white {
    width: 48px;
    height: 48px;
    background-color: #EEE;
    position: absolute;
    top: -48px;
    right: 0;
}

.hover-bottom-cut {
    width: 48px;
    height: 48px;
    background-color: #ffe342;
    border-radius: 0 30px 0 0;
    position: absolute;
    bottom: -48px;
    right: 0;
    z-index: 3;
}

.hover-bottom-white {
    width: 48px;
    height: 48px;
    background-color: #EEE;
    position: absolute;
    bottom: -48px;
    right: 0;
}

.sidebar-not-active-hover:hover {
    border-radius: 30px;
    z-index: 5;
}

.add-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 13px !important;
    width: 100%;
    padding: 10px;
    min-height: 550px
}

/*menu-add-item*/

.menu-image-upload {
    display: flex;
    flex-direction: column !important;
    text-align: center !important;
    align-items: center !important;
    height: fit-content !important;
    border-style: dashed !important;
    border-radius: 10px;
    padding: 10px !important;
}

.browse-grid {
    cursor: pointer;
    border-radius: 7px;
    margin-bottom: 12px;
    padding: 3px 20px 3px 20px;
}

.menu-price-box {
    height: 15px;
    margin-left: 5px;
    margin-right: 10px
}

.MuiTimeClock-root .MuiClockPointer-root,
.MuiTimeClock-root .MuiClock-pin,
.MuiTimeClock-root .MuiClockPointer-thumb,
.MuiTimeClock-root .MuiClock-amButton,
.MuiTimeClock-root .MuiClock-pmButton {
    background-color: #ffe342;
}

.MuiTimeClock-root .MuiClock-squareMask {
    cursor: pointer;
}

.MuiTimeClock-root .MuiClockPointer-thumb {
    border: 16px solid #ffe342;
}

#mainMapView .gmnoprint a, .gmnoprint span {
    display: none;
}

#mainMapView .gmnoprint div {
    background: none !important;
}

#mainMapView .gmnoprint {
    display: none;
}

#mainMapView .gm-style-cc {
    display: none;
}

#mainMapView .gm-style > div > div > a > div > img {
    display: none;
}

.menu-arrow-icon-grid {
    display: flex;
    justify-content: center;
    background-color: #fff;
    border: 1px solid #fff;
    border-radius: 10px;
    padding: 12px;
    margin-top: 10px;
}

.catOrderListView {
    user-select: none;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
}

.catOrderDragged {
    z-index: 10000;
}

.catMenuSearchTextInput .css-qtxfjx-MuiInputBase-root-MuiInput-root:before {
    border-bottom: 0.5px solid #CCC !important;
}

.menu-arrow-icon-grid1 {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    border: 1px solid #fff;
    border-radius: 10px;
    height: 56px
}

.menuListOrderDragged {
    z-index: 10000;
}

.menu-order-list-view {
    width: 100%;
}

.menu-list-main-grid {
    background-color: #fff;
    border-radius: 10px;
    width: 100%;
    margin-bottom: 12px;
    padding: 10px;
}

.sortable-view,
.menu-list-expand-vertical-icon-grid {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: grab;
}

.menu-list-image-grid {
    display: flex;
    align-items: center;
}

.menu-list-image {
    border-radius: 10px;
    width: 100%;
    height: 80px;
}

.menu-list-main-data-grid {
    padding-left: 15px
}

.menu-list-sub-data-grid {
    display: flex;
    align-items: center;
}

.menu-list-price-data-grid {
    display: flex;
    align-items: center;
}

.menu-list-horizontal-dot-icon-grid {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding-right: 10px;
}

.search-event-input,
.search-menu-input {
    position: relative;
    z-index: 4;
}

.search-event-input .MuiFilledInput-root,
.search-menu-input .MuiFilledInput-root {
    background-color: white !important;
}

#mainMapView .gm-style .gm-style-iw-t::after {
    background: none;
    box-shadow: none;
}

#mainMapView .gm-style-iw {
    top: -30px !important;
}

#mainMapView .gm-style-iw .map-location-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

#mainMapView .gm-style-iw .address-div {
    /*width: 150px;*/
    padding: 1.3px;
    border-radius: 2px
}

#mainMapView .gm-style-iw .rest-name {
    font-size: 10px;
    font-family: GilroyBold;
    text-align: center
}

#mainMapView .gm-style-iw .address-name {
    font-size: 10px;
    font-family: GilroyMedium;
    text-align: center;
}

#mainMapView .gmnoprint a, .gmnoprint span {
    display: none !important;
}

#mainMapView .gmnoprint div {
    background: none !important;
}

#mainMapView .gmnoprint {
    display: none !important;
}

#mainMapView .gm-style-cc {
    display: none !important;
}

#mainMapView .gm-style-iw-tc {
    display: none !important;
}

#mainMapView .gm-style > div > div > a > div > img {
    display: none !important;
}

.arModelListOrderDragged {
    z-index: 10000;
}
