.mainView {
  background-color: white;
  position: relative;
  width: 100%;
  height: 46px;
  border-radius: 12px;
  overflow: hidden;
}

.inputFile {
  position: absolute;
  z-index: 1;
}

.label {
  background-color: white;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 2;
}

.uploadView {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 100%;
}

.uploadText {
  flex: 1;
  font-size: 15px !important;
  font-family: GilroySemiBold !important;
}

.close {
  position: absolute;
  align-items: center;
  justify-content: center;
  display: flex;
  color: #000000;
  font-size: 17px !important;
  font-family: GilroyBlack !important;
  cursor: pointer;
  z-index: 3;
  right: 16px;
  top: 0;
  bottom: 0;
  margin: auto;
}

.errorText {
  color: #cd0000 !important;
  font-size: 10px !important;
  font-family: GilroyMedium !important;
}
