.title-view-text {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.back-btn {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.title-text.title {
  color: #000000;
  font-size: 18px;
  font-family: GilroySemiBold;
  margin-left: 12px;
}
