.mainView {
  background-color: #ffffff;
  /*height: calc(100vh - 180px);*/
  border-radius: 8px;
  display: flex;
  flex-direction: column !important;
  align-items: center;
  /*justify-content: space-between;*/
}

.mainBtnView {
  display: flex;
  align-items: center;
  justify-content: center;
}

.btnView {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  border: 2px solid #000000;
  width: 140px;
  height: 40px;
  border-radius: 8px;
  padding: 0 18px;
  cursor: pointer;
}

.btnText {
  color: #000000;
  font-size: 14px !important;
  font-family: GilroySemiBold !important;
}

.addBtnView {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #000000;
  width: 140px;
  height: 40px;
  border-radius: 8px;
  padding: 0 18px;
  cursor: pointer;
}

.addBtnText {
  color: #ffffff;
  font-size: 14px !important;
  font-family: GilroySemiBold !important;
}

.submitBtn {
  cursor: pointer;
  text-transform: capitalize !important;
  font-size: 14px !important;
  font-family: GilroySemiBold !important;
  border-radius: 8px !important;
  height: 44px !important;
  width: 100px !important;
}
