.leftArrowBox {
  display: flex;
  align-items: center;
  cursor: pointer;
  border-radius: 10px;
  padding: 10px 10px;
}

.restaurantInfo {
  font-family: GilroyBold !important;
  font-size: 20px;
}
.transactionsBtn {
  display: flex;
  align-items: center;
  cursor: pointer;
  border-radius: 10px;
  padding: 10px 20px;
}
.transactionsBtnText {
  font-size: 14px !important;
  font-family: GilroyRegular !important;
  color: #fff;
}
.imageDeleteBtn {
  display: flex;
  align-items: center;
  cursor: pointer;
  border-radius: 10px;
  padding: 10px 20px;
  border: 1px solid #000;
}
.imageDeleteBtnText {
  font-size: 14px !important;
  font-family: GilroySemiBold !important;
  color: #000;
}

.btnText {
  font-size: 14px !important;
  font-family: GilroySemiBold !important;
  color: #fff;
}
