.mainView {
  border: 1px solid #e1e1e1;
  box-shadow: 0px 0px 20px 0px #0000001a;
  border-radius: 10px;
  overflow: hidden;
}

.titleView {
  background-color: #263238;
  display: flex;
  align-items: center;
  height: 50px;
  cursor: pointer;
}

.titleText {
  flex: 1;
  color: #ffffff;
  font-size: 14px !important;
  font-family: GilroyBold !important;
}

.optionList {
  padding: 0 10px;
}

.optionView {
  background-color: #ffffff;
  border: 1px solid #e1e1e1;
  box-shadow: 0px 0px 20px 0px #0000001a;
  border-radius: 10px;
  overflow: hidden;
}

.optionTitleMainView {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.optionTitleView {
  display: flex;
  flex: 1;
}

.optionTitleText {
  font-size: 14px !important;
  font-family: GilroySemiBold !important;
}

.requiredView {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.requiredText {
  color: #777777;
  font-size: 13px !important;
  font-family: GilroySemiBold !important;
}

.optionItemList {
}

.itemView {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.itemNameView {
  display: flex;
  flex: 1;
}

.itemNameText {
  font-size: 14px !important;
  font-family: GilroySemiBold !important;
}

.itemRightView {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.actionBtn {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 42px;
  cursor: pointer;
  width: 100%;
  background-color: #000000;
}

.actionBtnText {
  color: #ffffff;
  font-size: 14px !important;
  font-family: GilroySemiBold !important;
}
