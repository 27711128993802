.mainView {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.actionBtn {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: #000000;
  height: 42px;
  border-radius: 10px;
  cursor: pointer;
  width: 140px;
}

.actionBtnText {
  color: #ffffff;
  font-size: 14px !important;
  font-family: GilroySemiBold !important;
}
